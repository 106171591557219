import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Tabs mdxType="Tabs">
  <Tab label="1.7.0 (actual)" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.7.0.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.6.0" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.6.0.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.5.0" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.5.0.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.4.2" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.4.2.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.4.1" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.4.1.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.4.0" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.4.0.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.3.1" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.3.1.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.3.0" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.3.0.yaml" mdxType="SwaggerUI" />
  </Tab>
  <Tab label="1.2.0" mdxType="Tab">
    <SwaggerUI url="/pastpay/pastpay-api.1.2.0.yaml" mdxType="SwaggerUI" />
  </Tab>
    </Tabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      